import { searchApi } from '@/api';
import { ELASTIC_SEARCH_SIZE, SEARCH_ENGINE_FUNCTIONS } from '@/constants/search';

const appURL = 'campuses';
const searchCounter = `${appURL}/counter`;
const searchAll = `${appURL}/all`;
const termSearch = `${appURL}/term`;

// This controller is used to abort previous requests when a new one is made
// It fixes the problem of having multiple requests running at the same time and
// returning the wrong results. A new instance is created every time a new request is made
let textSearchRequestController;

export default {
  /** Gets all campuses contained in the bounds from the elastic search
   * @param {Object} param0 - The parameters to use in the search
   * @param {Object} param0.bounds - The bounds to search in (lat/lon format)
   * @param {Object} param0.filters - The filters to apply to the search
   * @param {Number} param0.size - The maximum number of results to return (optional) - defaults to ELASTIC_SEARCH_SIZE
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveCampuses({
    bounds,
    filters,
    size = ELASTIC_SEARCH_SIZE,
  }) {
    return searchApi.post(`${appURL}/poly_filter`, {
      other_filters: filters,
      search_bounds: bounds,
      search_size: size,
    });
  },
  /** Gets all campuses around a location from the elastic search
   * @param {Object} param0 - The parameters to use in the search
   * @param {Object} param0.location - The location to search around (lat/lng format)
   * @param {Object} param0.filters - The filters to apply to the search
   * @param {Number} param0.size - The maximum number of results to return (optional) - defaults to ELASTIC_SEARCH_SIZE
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   */
  getCampusesAround({
    location: { lat, lng },
    filters,
    size = ELASTIC_SEARCH_SIZE,
    fieldsRequired,
  }) {
    return searchApi.post(`${appURL}/geo`, {
      search_size: size,
      search_location: {
        lon: lng,
        lat,
      },
      search_distance: '3km',
      filter_match: filters,
      fields_required: fieldsRequired,
    });
  },
  /** Fetch campuses that match the search term. The search is done across all the fields, but some of them are
   *  matched using a partial match, while others are matched using an exact match.
   * The fields that are matched using a partial match are:
   * - campus_name
   * - campus_code
   * - institution_name
   * - institution_code
   * - commune
   * - uuid
   *
   * @param {Object} param0.searchTerm - The text to search for across the fields
   * @param {Object} param0.requiredFields - Fields that are required to be returned in the response
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   */
  getCampusesFromText({ searchTerm, requiredFields, filters }) {
    // Abort previous request
    if (textSearchRequestController) {
      textSearchRequestController.abort();
    }
    textSearchRequestController = new AbortController();
    const { signal } = textSearchRequestController;
    return searchApi.post(
      termSearch,
      {
        search_term: searchTerm,
        search_size: 20,
        search_operator: 'OR',
        search_partial: false,
        fields_required: requiredFields,
        other_filters: filters && [
          ...filters,
          /**
            {
              "fieldname": FIELDS.COMMUNE,
              "fieldvalue": "Palmira",
              "exact": true // IMPORTANT! Add in Palmira
            },
            {
              "fieldname": FIELDS.SECTOR,
              "fieldvalue": "1",
            },
          ],
          */
        ],
      },
      {
        signal,
      },
    );
  },
  /**
   * Fetch a campus based on its key, which is either the uuid or the campus_code. All the fields are returned.
   * @param {String} param0.key - The key to search for
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   */
  getCampusFromKey({ key }) {
    return searchApi.get(`${appURL}/${key}`);
  },
  elasticFilteredSearch(filters, fieldsRequired) {
    return searchApi.post(`${searchAll}`, {
      search_size: ELASTIC_SEARCH_SIZE,
      search_operator: 'OR',
      filter_match: [...filters],
      fields_required: fieldsRequired,
    });
  },
  elasticCampusAttributeSearch({ campus, fieldsRequired }) {
    // FIXME: ESTO ESTÁ BUSCANDO PARCIALMENTE, NO EXACTAMENTE - CAMBIAR A EXACTO
    /**
     * Esto está siendo usado actualmente en dos partes:
     * - Para ir a buscar campuses de un director
     * - Para ir a buscar el campus de DGE
     *
     * Se está rompiendo porque busca hace un match del uuid/campus_code parcialmente, no exactamente
     *
     * Se debe cambiar a que busque exactamente, y hay dos opciones:
     * - Crear un endpoint de elasticSearch que busque exactamente y retorne un solo resultado con los campos requeridos
     *   Esto sería lo mismo que el endpoint de getCampusFromKey, pero que retorne los campos requeridos (reduntante)
     * - Enviar en la request los nuevos campos sobre los que se quiere hacer el match exacto. El problema con esta
     *   solución es que para campus_code habrá que especificar el keyword a la mala (uuid, campus_code.keyword)
     *   (FIXME: ESTA ES LA SOLUCIÓN QUE SE ESTÁ USANDO ACTUALMENTE - PERO LA OPCIÓN 3 ES MEJOR)
     * - Lo mismo que (1), pero que permita pasar múltiples códigos de campus, lo que justifica que sea un endpoint
     *   aparte (creo que es la mejor opción)
     */
    const searchFields = ['uuid', { name: 'campus_code', exact: true }];

    // TODO: No debería buscar por term
    return searchApi.post(termSearch, {
      search_term: campus,
      search_size: 1,
      search_fields: searchFields,
      fields_required: fieldsRequired,
    });
  },
  /**
   * Fetch campuses that match the search term based on the filters.
   * @param {Object} param0 - The parameters to use in the search
   * @param {Number} param0.size - The number of results to return
   * @param {Number} param0.from - The index of the first result to return
   * @param {Array} param0.mustFilters - Filters that must match
   * @param {Array} param0.mustNotFilters - Filters that must not match
   * @param {Array} param0.fieldsRequired - Fields that are required to be returned in the response (optional)
   *
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   *
   * The fieldsRequired parameter is optional, and if it is not passed, the standard fields will be returned.
   *
   * @example
   * searchCampuses({
   *  size: 20,
   *  from: 0,
   *  mustFilters: [
   *     {
   *       fieldname: FIELDS.PAYMENT_CATEGORY, // 'institution_payment_campus.payment_category.id'
   *       fieldvalues: [1, 2],
   *     },
   *     {
   *       fieldname: 'institution_payment_campus.payment_type.id'
   *       fieldvalue: '2',
   *     },
   *   ],
   *   mustNotFilters: [
   *     {
   *       fieldname: FIELDS.CAMPUS_CODE, // 'campus_code'
   *       fieldvalues : ['000001', '000002'],
   *       exact: true,
   *     },
   *  ],
   *  fieldsRequired: [FIELDS.CAMPUS_CODE], // 'campus_code'
   * });
   *
   *
   * The above example will return the first 20 campuses that have a payment category of 1 or 2, a payment type of 2,
   * and that do not have a campus code of 000001 or 000002. Only the campus_code field will be returned.
   *
   */
  searchCampuses({
    size = ELASTIC_SEARCH_SIZE, from, mustFilters, mustNotFilters, fieldsRequired, randomize = false,
  }) {
    return searchApi.post(searchAll, {
      search_size: size,
      fields_required: fieldsRequired,
      search_from: from,
      filter_match: mustFilters,
      filter_matchnot: mustNotFilters,
      randomize,
    });
  },

  elasticSearchCampuses(otherFilters) {
    return searchApi.post(searchAll, {
      search_size: ELASTIC_SEARCH_SIZE,
      search_operator: 'OR',
      filter_match: [...otherFilters],
      fields_required: ['location', 'campus_code'],
    });
  },

  elasticSchoolCommune(filter) {
    return searchApi.post(searchAll, {
      search_from: 0,
      search_size: 5000,
      fields_required: [
        'campus_name',
        'commune',
        'image_thumb',
        'uuid',
        'payment',
        'tuition',
        'sector',
        'grades_info',
        'institution_code',
        'institution_name',
      ],
      filter_match: filter ?? [],
    });
  },
  elasticSchoolInstitution(filter) {
    return searchApi.post(searchAll, {
      search_from: 0,
      search_size: 1,
      fields_required: ['institution_name', 'institution_code', 'commune'],
      filter_match: filter ?? [],
    });
  },
  // DELETION CANDIDATE
  elasticSchoolCommuneFavorites(filter, searchFrom) {
    return searchApi.post(searchAll, {
      search_from: searchFrom,
      search_size: 25,
      filter_match: filter ?? [],
    });
  },

  elasticCommuneList() {
    return searchApi.post(searchCounter, {
      field_sum: 'programs.vacancies.regular_vacancies',
      field_group: 'commune',
      search_size: 5000,
    });
  },
  /**
   * Executes a multi match query using only 'geo' queries. This means that the results will be campuses that are
   * around the location.
   * @param {*} param0 - The parameters to use in the search
   * @param {*} param0.location - The location to search around (lat/lng format)
   * @param {*} param0.shared - The filters that are shared between all the queries
   * @param {*} param0.divergent - The filters that are different between the queries
   * @param {*} param0.matchField - The field to match on (defaults to campus UUID)
   * @param {*} param0.detailLevel - The detail level of the search:
   * - 0: Only the count of results will be returned
   * - 1: The count of results and an array of match_field values is returned
   * - 2: Same as 1, but adds the detail on a per-query basis
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   *
   *
   * The shared parameter is an array of filters that will be applied to all the queries. The divergent parameter is an
   * array of arrays of filters. Each array of filters will be applied to a query. The results will be the number of
   * campuses that are around the location that match all the queries submitted.
   * Logically, this is equivalent to the following:
   * shared_1 & shared_2 & ... & shared_n & (divergent_1 | divergent_2 | ... | divergent_n)
   *
   * @example <caption>Get Campuses with good performance that offer scholarship or are cheap</caption>
   * getCampusesAroundMultiMatchCount({
   * location: { lat: ..., lng: ... },
   * shared: [FILTER_PRESETS.GOOD],
   * divergent: [FILTER_PRESETS.HAS_SCHOLARSHIP, FILTER_PRESETS.CHEAP],
   * });
   */
  getCampusesAroundMultiMatchCount({
    location, shared, divergent, matchField, detailLevel,
  }) {
    const queryBuilder = (filters) => ({
      type: SEARCH_ENGINE_FUNCTIONS.GEO_DISTANCE.path,
      body: {
        search_location: {
          lon: location.lng,
          lat: location.lat,
        },
        search_distance: '2km',
        filter_match: [...shared, ...filters],
      },
    });
    return searchApi.post(`${appURL}/multi_query_count/`, {
      queries: divergent.map(queryBuilder),
      match_field: matchField,
      detail_level: detailLevel,
    });
  },
};
