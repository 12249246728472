<template>
  <div id="panels" class="campus-detail__panels">
    <v-expansion-panels accordion flat :value="openedByDefault">
      <v-expansion-panel
        v-for="({
          name, icon, title, iconSize, isEnable, tooltip,
        }, index) in panels"
        :id="name"
        :key="index"
        :disabled="!isEnable"
        class="mt-2 campus-detail__panels--disabled"
      >
        <v-expansion-panel-header class="pb-0 px-0 pt-0" @click="trackMixPanelFunc(name)">
          <template #actions>
            <v-icon color="#BBA9FF">
              $expand
            </v-icon>
          </template>
          <template #default="{ open }">
            <div class="d-flex align-center">
              <img
                :src="require(`@/assets/icons/campusDetail/${icon}`)"
                :width="iconSize"
                :class="{ 'campus-detail__panels__image-disable': !isEnable }"
                alt="Icono del panel"
              />
              <h3 class="campus-detail__panels__title campus-detail__panels--white ml-6 mr-3">
                {{ title }}
              </h3>
              <v-tooltip v-if="open && tooltip !== ''" bottom content-class="custom-tooltip">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon dark small color="#1E2D4C" v-on="on">
                    <v-icon dark>
                      mdi-information
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ tooltip }}
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <!-- ADMISSION -->
          <Admission
            v-if="name === 'admission'"
            :all-admission-processes="campusDetail.campus_processes"
          />

          <!-- LEADERSHIP -->
          <Leadership
            v-if="name === 'leadership'"
            :contact="getLeaderships"
            :description="getLeadershipDescription"
          />

          <!-- TEAM -->
          <Teachers
            v-if="name === 'teachers'"
            :total="getDataTeachers"
            :others="getSupport"
            :description="getTeachersDescription"
          />

          <!-- STUDENTS -->
          <Students
            v-if="name === 'students'"
            :members="campusDetail.institutionmember_campus"
            :description="getStudentsDescription"
          />

          <!-- INFRASTRUCTURE -->
          <Infrastructure
            v-if="name === 'infrastructure'"
            :infrastructures="getInfrastructure"
            :canonical-link="canonicalLink"
            :safety="getSafety"
            :description="getInfrastructureDescription"
          />

          <!-- SPORTS -->
          <Sports
            v-if="name === 'sports'"
            :sports="getSports"
            :canonical-link="canonicalLink"
            :description="getSportsDescription"
          />

          <!-- EXTRACURRICULAR -->
          <Extracurriculars
            v-if="name === 'extracurriculars'"
            :extracurriculars="getExtraActivity"
            :canonical-link="canonicalLink"
            :description="getExtraactivitiesDescription"
          />
          <Language
            v-if="name === 'languages'"
            :languages="getLanguages"
            :description="getLanguagesDescription"
          />

          <!-- Price and costs  -->
          <Price
            v-if="name === 'price'"
          />

          <!-- Programs  -->
          <Programs
            v-if="name === 'programs'"
          />

          <!-- Performance  -->
          <Performance
            v-if="name === 'performance'"
          />

          <!-- Main activities -->
          <MainActivities
            v-if="name === 'main-activities'"
            :partnerships="getPartnerships"
          />

          <!-- Vacancies  -->
          <Vacancies
            v-if="name === 'vacancy'"
            :description="getVacanciesDescription"
          />

          <MainActivities
            v-if="name && getPartnershipByName(name).length > 0 && name !== 'main-activities'"
            class="mt-4"
            :partnerships="getPartnershipByName(name)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Admission from '@/components/explorer/general/campus_detail/panels/Admission.vue';
import Extracurriculars from '@/components/explorer/general/campus_detail/panels/Extracurriculars.vue';
import Infrastructure from '@/components/explorer/general/campus_detail/panels/Infrastructure.vue';
import Language from '@/components/explorer/general/campus_detail/panels/Language.vue';
import Leadership from '@/components/explorer/general/campus_detail/panels/Leadership.vue';
import MainActivities from '@/components/explorer/general/campus_detail/panels/MainActivities.vue';
import Performance from '@/components/explorer/general/campus_detail/panels/Performance.vue';
import Programs from '@/components/explorer/general/campus_detail/panels/Programs.vue';
import Price from '@/components/explorer/general/campus_detail/panels/Price.vue';
import Sports from '@/components/explorer/general/campus_detail/panels/Sports.vue';
import Students from '@/components/explorer/general/campus_detail/panels/Students.vue';
import Teachers from '@/components/explorer/general/campus_detail/panels/Teachers.vue';
import Vacancies from '@/components/explorer/general/campus_detail/panels/Vacancies.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'CampusDetailPanels',
  components: {
    Infrastructure,
    Sports,
    Extracurriculars,
    Language,
    // Team,
    Students,
    Admission,
    Leadership,
    Price,
    Performance,
    Programs,
    Vacancies,
    Teachers,
    MainActivities,
  },
  props: {
    canonicalLink: { type: String, required: true },
    scrollToAdmissions: { type: Boolean, default: false },
  },
  data() {
    return {
      panels: [],
      openedByDefault: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      membersLabels: 'options/membersLabels',
      redirectedToProcesses: 'institutions/redirectedToProcesses',
    }),
    institutionText() {
      return this.campusDetail?.institutiontext_campus[0] ?? [];
    },
    getPartnerships() {
      return this.campusDetail?.partership_campus?.map((partnership) => partnership.partnerships_label) ?? [];
    },
    getLanguages() {
      const listLanguages = [];
      const languages = this.campusDetail?.language_campus.map((language) => ({
        language: language.language_label,
        level: language.language_level_label,
      })) ?? [];

      // filter languages
      const setLanguages = new Set(languages.map(JSON.stringify));
      const languagesFilter = Array.from(setLanguages).map(JSON.parse);

      // formated languages
      languagesFilter.forEach((language) => {
        const hasLanguage = listLanguages.findIndex(
          (listLanguage) => listLanguage.language.id === language.language.id,
        );

        if (hasLanguage === -1) {
          const newLanguage = {
            language: language.language,
            level: language.level !== null ? [language.level] : [],
          };
          listLanguages.push(newLanguage);
        } else {
          listLanguages[hasLanguage].level.push(language.level);
        }
      });

      return listLanguages ?? [];
    },

    getInfrastructure() {
      const infrastructure = this.campusDetail.infraestructure_campus;
      infrastructure.sort((a, b) => {
        if (a.infra_label.id === 99) {
          return a.descrip.localeCompare(b.infra_label.infra_name);
        }
        if (b.infra_label.id === 99) {
          return a.infra_label.infra_name.localeCompare(b.descrip);
        }
        return a.infra_label.infra_name.localeCompare(b.infra_label.infra_name);
      });
      return infrastructure;
    },
    getSafety() {
      const safety = this.campusDetail.safety_campus;
      safety.sort((a, b) => {
        if (a.safety_label.id === 99) {
          return a.descrip.localeCompare(b.safety_label.name);
        }
        if (b.safety_label.id === 99) {
          return a.safety_label.name.localeCompare(b.descrip);
        }
        return a.safety_label.name.localeCompare(b.safety_label.name);
      });
      return safety;
    },
    getSports() {
      const sport = this.campusDetail.sport_campus;
      sport.sort((a, b) => {
        if (a.sport_label_id === 99) {
          return a.descrip.localeCompare(b.sport_label);
        }
        if (b.sport_label_id === 99) {
          return a.sport_label.localeCompare(b.descrip);
        }
        return a.sport_label.localeCompare(b.sport_label);
      });
      return sport;
    },
    getExtraActivity() {
      const extra = this.campusDetail.extraactivity_campus;
      extra.sort((a, b) => {
        if (a.extra_label.id === 99) {
          return a.descrip.localeCompare(b.extra_label.extra_name);
        }
        if (b.extra_label.id === 99) {
          return a.extra_label.extra_name.localeCompare(b.descrip);
        }
        return a.extra_label.extra_name.localeCompare(b.extra_label.extra_name);
      });
      return extra;
    },
    getSupport() {
      const support = this.campusDetail.support_campus;
      support.sort((a, b) => {
        if (a.support_label.id === 99) {
          return a.descrip.localeCompare(b.support_label.support_name);
        }
        if (b.support_label.id === 99) {
          return a.support_label.support_name.localeCompare(b.descrip);
        }
        return a.support_label.support_name.localeCompare(b.support_label.support_name);
      });
      return support;
    },
    getMembers() {
      return this.membersLabels?.filter((m) => m.id !== 1);
    },
    getDataTeachers() {
      const copyMembers = this.getMembers;
      const members = {};

      copyMembers.forEach((member) => {
        const hasMember = this.campusDetail?.institutionmember_campus
          .filter((m) => m.member_label.id === member.id)
          .map((m) => ({ count: m.total_members, name: member.member_name, year: m.year }))
          .sort((a, b) => b.year - a.year) ?? [];

        if (hasMember.length > 0) {
          members[member.id] = { ...hasMember[0] };
        }
      });
      return members;
    },
    getLeaderships() {
      let leaderships = this.campusDetail?.institutioncontact_campus ?? [];
      // id 1 = Office; id 2 && 3 managers
      leaderships = leaderships.filter(
        (leadership) => leadership.contact_label.id > 1 && leadership.contact_label.id < 4,
      );
      leaderships = leaderships.map((leadership) => {
        const formated = {
          name: leadership.name,
          ocupation: leadership.contact_label.contact_name,
        };
        return formated;
      });

      return leaderships;
    },
    getLeadershipDescription() {
      return this.institutionText.direction_text ?? '';
    },
    getTeachersDescription() {
      return this.institutionText.teacher_text ?? '';
    },
    getStudentsDescription() {
      return this.institutionText.students_text ?? '';
    },
    getInfrastructureDescription() {
      return this.institutionText.infrastructure_text ?? '';
    },
    getSportsDescription() {
      return this.institutionText.sports_text ?? '';
    },
    getExtraactivitiesDescription() {
      return this.institutionText.extraactivities_text ?? '';
    },
    getLanguagesDescription() {
      return this.institutionText.languages_text ?? '';
    },
    getVacanciesDescription() {
      return this.institutionText.vacancies_text ?? '';
    },
    hasStudents() {
      return this.campusDetail.institutionmember_campus.length > 0;
    },
  },
  watch: {
    redirectedToProcesses(value) {
      if (value) {
        this.openedByDefault = 0;
      }
    },
  },
  mounted() {
    if (this.scrollToAdmissions) {
      setTimeout(() => {
        const topPosition = document.getElementById('admission').getBoundingClientRect().top;
        document.querySelector('.favorites__campus-detail-container').scrollTo({
          top: topPosition - 100,
          behavior: 'smooth',
        });
        this.openedByDefault = 0;
      }, 400);
    }
  },
  created() {
    this.setPanelsCampusDetail();
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    getPartnershipByName(name) {
      return this.campusDetail?.partership_campus
        .filter((partnership) => partnership.partnerships_label.section_label.section_code === name)
        .map((partnership) => partnership.partnerships_label);
    },
    setPanelsCampusDetail() {
      this.panels = [
        {
          name: 'admission',
          title: this.$t('campus-details.admission-process'),
          icon: 'process-icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail?.campus_processes?.length > 0,
          tooltip: '',
        },
        {
          name: 'price',
          title: this.$t('campus-details.price_title'),
          icon: 'price_icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail?.payment_campus?.length > 0,
          tooltip: this.$t('campus-details.tooltip.price'),
        },
        {
          name: 'programs',
          title: 'Programas',
          icon: 'programs_icon.svg',
          iconSize: '30px',
          isEnable: this.campusDetail.program_campus.length > 0,
          tooltip: this.$t('campus-details.tooltip.programs'),
        },
        {
          name: 'performance',
          title: 'Rendimiento Académico',
          icon: 'performance_icon.svg',
          iconSize: '30px',
          isEnable: this.campusDetail.performance.length > 0 || this.campusDetail.achievement_campus.length > 0,
          tooltip: this.$t('campus-details.tooltip.performance'),
        },
        {
          name: 'leadership',
          title: this.$t('campus-details.leadership'),
          icon: 'star.svg',
          iconSize: '40px',
          isEnable: this.getLeaderships.length > 0,
          tooltip: '',
        },
        {
          name: 'teachers',
          title: this.$t('campus-details.team.title'),
          icon: 'teachers-icon.svg',
          iconSize: '40px',
          isEnable: true,
          tooltip: this.$t('campus-details.tooltip.teamInfo'),
        },
        {
          name: 'students',
          title: this.$t('campus-details.students'),
          icon: 'student.svg',
          iconSize: '40px',
          isEnable: this.hasStudents,
          tooltip: this.$t('campus-details.tooltip.students'),
        },
        {
          name: 'infrastructure',
          title: this.$t('campus-details.infrastructure'),
          icon: 'infrastructure-icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail.infraestructure_campus.length > 0 || this.campusDetail.safety_campus?.length > 0,
          tooltip: this.$t('campus-details.tooltip.infrastructureInfo'),
        },
        {
          name: 'sports',
          title: this.$t('campus-details.activities.sports'),
          icon: 'sports-icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail.sport_campus.length > 0,
          tooltip: this.$t('campus-details.tooltip.sportsInfo'),
        },
        {
          name: 'extracurriculars',
          title: this.$t('campus-details.activities.extra_programmatic'),
          icon: 'extracurricular-icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail.extraactivity_campus.length > 0,
          tooltip: this.$t('campus-details.tooltip.activitiesInfo'),
        },
        {
          name: 'languages',
          title: this.$t('campus-details.languages.title'),
          icon: 'language-icon.svg',
          iconSize: '40px',
          isEnable: this.campusDetail.language_campus.length > 0,
          tooltip: this.$t('campus-details.tooltip.teamInfo'),
        },
        {
          name: 'main-activities',
          title: this.$t('campus-details.main_activities.title'),
          icon: 'main_activities.svg',
          iconSize: '35px',
          isEnable: this.getPartnerships.length > 0,
          tooltip: '',
        },
        {
          name: 'vacancy',
          title: this.$t('campus-details.vacancies.title'),
          icon: 'chair_icon.svg',
          iconSize: '30px',
          isEnable: true,
          tooltip: '',
        },
      ];

      // this.panels = this.panels.filter((panel) => panel.isEnable);
    },
    trackMixPanelFunc(nameTrack) {
      if (nameTrack === this.showingPanel) {
        this.showingPanel = '';
      } else {
        const track = `sp_school_${nameTrack}`;
        trackMixPanel(track, {
          school_id: this.campusDetail.uuid,
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
          name: this.campusDetail.campus_name,
        });
        this.showingPanel = nameTrack;
      }
    },
  },
};
</script>
