<template>
  <section
    class="application-to-date"
    :class="showApplicationsToDate ? 'application-to-date-active' : ''"
  >
    <button type="button" class="w-full d-flex justify-center" @click="showApplications()">
      <TextAtom
        :value="$t('feedback.applications_to_date.title')"
        tag="p"
        font="poppins"
        color="secondary-medium-light"
        weight="500"
        size="normal"
      />
    </button>
    <div v-if="showApplicationsToDate" class="mt-2">
      <figure v-for="({ image, text, size }, index) in getApplicationsToDate" :key="index" class="mt-3">
        <div class="application-image">
          <SvgIcon
            :icon="image"
            alt="application icon"
            :size="size || '23'"
          />
        </div>
        <figcaption class="ml-2">
          <TextAtom
            :value="text"
            color="neutral-800"
            font="inter"
            size="default"
            class="text-left feedback-text-wrap-balance"
          />
        </figcaption>
      </figure>
      <TextAtom
        :value="$t('feedback.applications_to_date.message')"
        tag="p"
        font="inter"
        color="neutral-800"
        weight="600"
        size="small"
        class="mt-3"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'ApplicationsToDate',
  components: {
    TextAtom, SvgIcon,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
    openApplications: {
      type: Boolean,
      default: false,
      required: false,
    },
    campusCode: {
      type: String,
      default: '',
    },
    application: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showApplicationsToDate: false,
    };
  },
  computed: {
    ...mapGetters({
      feedbackId: 'feedback/feedbackId',
    }),
    getApplicationsToDate() {
      const messageAvailable = +this.feedbackId === 5 ? 'feedback.applications_to_date.available_id_5' : 'feedback.applications_to_date.available';
      return [
        {
          image: 'seat_available.svg',
          text: this.$t(messageAvailable, { total: this.stats.vacancies }),
        },
        {
          image: 'person-gray.svg',
          text: this.$t('feedback.applications_to_date.enrollment', { total: `${this.stats?.assigned_higher_ma_percentil25} - ${this.stats?.assigned_higher_ma_percentil75}` }),
          size: '15',
        },
        {
          image: 'total_seats.svg',
          text: this.$t('feedback.applications_to_date.total', { total: `${this.stats?.n_considered_tot_percentil25} - ${this.stats?.n_considered_tot_percentil75}` }),
        },
        {
          image: 'seat_higher_priority.svg',
          text: this.$t('feedback.applications_to_date.higher_priority', { total: `${this.stats?.assigned_higher_no_ma_percentil25} - ${this.stats?.assigned_higher_no_ma_percentil75}` }),
        },
        {
          image: 'seat_assigned_same_priority.svg',
          text: this.$t('feedback.applications_to_date.assigned_same_priority', { total: `${this.stats?.assigned_equal_percentil25} - ${this.stats?.assigned_equal_percentil75}` }),
        },
        {
          size: '25',
          image: 'seat_not_assigned.svg',
          text: this.$t('feedback.applications_to_date.not_assigned', { total: `${this.stats?.nonassigned_percentil25} - ${this.stats?.nonassigned_percentil75}` }),
        },
      ];
    },
  },
  mounted() {
    this.showApplicationsToDate = this.openApplications;
  },
  methods: {
    showApplications() {
      trackMixPanel(
        'click_feedbackchile_postind_verpostulaciones2',
        {
          campusCode: this.campusCode,
          applicationsToDate: { ...this.getApplicationsToDate },
          campus: this.application,
        },
      );
      this.showApplicationsToDate = !this.showApplicationsToDate;
    },
  },
};
</script>
<style lang="scss" scoped>

figure {
  display: flex;
  align-items: center;
}
.application-to-date {
  max-width: 100% !important;
  background: white !important;
  border: 1px solid var(--secondary-medium-light);
  padding: 19px 23px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 28px;
  margin: auto;
  height: 45px;
}

.application-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}

figcaption {
  width: calc(100% - 30px) !important;
}

.application-to-date-active {
  max-width: 95% !important;
  border-radius: 8px;
  height: 100% !important;
}
</style>
