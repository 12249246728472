import {
  CAMPUS_PROPERTIES,
  DEFAULT_CARD_PROPERTIES,
  TETHER_CATEGORY,
  TETHER_PAYMENT_CATEGORY,
  PERFORMANCE_ICON_STYLE,
} from '@/constants/category';
import {
  CHILE_RESEARCH_STEP,
  DOM_RESEARCH_STEP,
} from '@/constants/research';
import { MEASUREMENT_UNIT } from '@/constants/general';
import { GEO_SEARCH_MODES } from '@/constants/search';
import CONFIG from '@/config';

// PERFORMANCE
const CHILE_PERFORMANCE_CATEGORY = Object.freeze({
  1: TETHER_CATEGORY.LOW,
  2: TETHER_CATEGORY.MEDIUM_LOW,
  3: TETHER_CATEGORY.MEDIUM,
  4: TETHER_CATEGORY.HIGH,
});

const PERU_PERFORMANCE_CATEGORY = Object.freeze({
  1: TETHER_CATEGORY.LOW,
  2: TETHER_CATEGORY.MEDIUM_LOW,
  3: TETHER_CATEGORY.MEDIUM,
  4: TETHER_CATEGORY.HIGH,
});

const DOM_PERFORMANCE_CATEGORY = Object.freeze({
  1: TETHER_CATEGORY.LOW,
  2: TETHER_CATEGORY.MEDIUM_LOW,
  3: TETHER_CATEGORY.MEDIUM,
  4: TETHER_CATEGORY.HIGH,
});

const COLOMBIA_PERFORMANCE_CATEGORIES = Object.freeze({
  1: TETHER_CATEGORY.HIGH,
  2: TETHER_CATEGORY.HIGH,
  3: TETHER_CATEGORY.MEDIUM,
  4: TETHER_CATEGORY.MEDIUM_LOW,
  5: TETHER_CATEGORY.LOW,
  6: TETHER_CATEGORY.NO_DATA,
});

// CAMPUS CATEGORY
const CARD_PROPERTIES_NEW_HAVEN = Object.freeze([
  CAMPUS_PROPERTIES.ADMISSION_RISK,
  CAMPUS_PROPERTIES.WALK_TIME,
  CAMPUS_PROPERTIES.CAR_TIME,
  CAMPUS_PROPERTIES.BUS_TIME,
]);

// TODO: Fix this eventually. The matcher shouldn't be here necessarily (maybe on utils?)
const SCHOLARSHIPS_CHILE = Object.freeze({
  1: {
    agreementId: 1,
    name: 'Gratis con SEP',
    tooltip: 'Este establecimiento tiene gratuidad con SEP',
    key: 'SEP',
    matcher: (applicant) => applicant?.prioritys?.appl_prios?.has_sep,
  },
});

// TODO: In the endpoints that require a stage, multiple values should be allowed

// TENANT
const TETHER_TENANT = Object.freeze({
  PER: {
    NAME: 'Perú',
    ABBREVIATION: 'PER',
    CATEGORIES: {
      PERFORMANCE: PERU_PERFORMANCE_CATEGORY,
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: {},
    },
    DEFAULTS: {
      GRADES: [7, 8, 9, 10, 11, 12],
      LOCATION: {
        municipalityName: 'Lima',
        coordinates: {
          // Lima
          lat: -12.046374,
          lng: -77.042793,
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.KM,
      CURRENCY: 'DOP',
      LOCALE: 'es-DO',
      SHAPE: {
        POLYGON: 'shapeFiles/provinciasPeru.json',
        DISTRICT: 'shapeFiles/distritosPeru.json',
      },
      FILTERS: {
        GRADES: [7, 8, 9, 10, 11, 12],
        PAYMENTS: [1, 2],
        PERFORMANCE: [3, 4],
        DISTANCE: 2,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ARROWS,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3, 4, 5, 6],
      // RD has no public admission systems to risk yet
      PUBLIC_ADMISSION_SYSTEMS: [],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: false,
      },
      CARD: {
        PROPERTIES: DEFAULT_CARD_PROPERTIES,
      },
      FILTERS: {
        PARTNERSHIPS: true,
        PAYMENT: true,
        PERFORMANCE: true,
        SPECIALTIES: false,
        VACANCIES: false,
      },
      SIMULATION: {
        ENABLED: false,
        YEAR_RANGE: [],
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: false,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: true,
        ORGANIC: false,
        STEP: DOM_RESEARCH_STEP,
      },
    },
  },
  DOM: {
    NAME: 'República Dominicana',
    ABBREVIATION: 'DO',
    CATEGORIES: {
      PERFORMANCE: DOM_PERFORMANCE_CATEGORY,
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: {},
    },
    DEFAULTS: {
      GRADES: [7, 8, 9, 10, 11, 12],
      LOCATION: {
        municipalityName: 'Santo Domingo',
        coordinates: {
          // Santo Domingo Ciudad Colonial
          lat: 18.472994,
          lng: -69.8915799,
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.KM,
      CURRENCY: 'DOP',
      LOCALE: 'es-DO',
      SHAPE: {
        POLYGON: 'shapeFiles/provincesDom.json',
        DISTRICT: 'shapeFiles/municipalitiesDom.json',
      },
      FILTERS: {
        GRADES: [7, 8, 9, 10, 11, 12],
        PAYMENTS: [1, 2],
        PERFORMANCE: [3, 4],
        DISTANCE: 2,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ARROWS,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3, 4, 5, 6],
      // RD has no public admission systems to risk yet
      PUBLIC_ADMISSION_SYSTEMS: [],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: false,
      },
      CARD: {
        PROPERTIES: DEFAULT_CARD_PROPERTIES,
      },
      FILTERS: {
        PARTNERSHIPS: true,
        PAYMENT: true,
        PERFORMANCE: true,
        SPECIALTIES: false,
        VACANCIES: false,
      },
      SIMULATION: {
        ENABLED: false,
        YEAR_RANGE: [],
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: false,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: true,
        ORGANIC: false,
        STEP: DOM_RESEARCH_STEP,
      },
      PUBLIC_ENROLLMENT: {
        ENABLED: false,
        DEFAULT_PROCESS: '',
        DEFAULT_PROCESS_CODE: '',
      },
    },
  },
  CHILE: {
    NAME: 'Chile',
    ABBREVIATION: 'CL',
    CATEGORIES: {
      PERFORMANCE: CHILE_PERFORMANCE_CATEGORY,
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: SCHOLARSHIPS_CHILE,
    },
    DEFAULTS: {
      GRADES: [],
      LOCATION: {
        municipalityName: 'Santiago',
        coordinates: {
          // Pangui
          lat: -33.5246231,
          lng: -70.6022981,
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.KM,
      CURRENCY: 'CLP',
      LOCALE: 'es-CL',
      SHAPE: {
        POLYGON: 'shapeFiles/chile/regiones.json',
        DISTRICT: 'shapeFiles/chile/comunas.json',
      },
      FILTERS: {
        GRADES: [],
        PAYMENTS: [1, 2],
        PERFORMANCE: [3, 4],
        DISTANCE: 2,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ARROWS,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3, 4, 5, 6],
      PUBLIC_ADMISSION_SYSTEMS: [1, 3, 4],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: true,
      },
      CARD: {
        PROPERTIES: DEFAULT_CARD_PROPERTIES,
      },
      FILTERS: {
        PARTNERSHIPS: true,
        PAYMENT: true,
        PERFORMANCE: true,
        SPECIALTIES: true,
        VACANCIES: true,
      },
      SIMULATION: {
        ENABLED: true,
        YEAR_RANGE: [2019, 2023], // 2019 raises an error for some campuses
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: true,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: true,
        ORGANIC: true,
        FOR_NEW_USERS: false,
        STEP: CHILE_RESEARCH_STEP,
      },
    },
    PUBLIC_ENROLLMENT: {
      ENABLED: true,
      DEFAULT_PROCESS: 'anotate-en-la-lista',
      DEFAULT_PROCESS_CODE: 'PUBLIC_ENROLLMENT',
    },
  },
  COLOMBIA: {
    NAME: 'Colombia',
    ABBREVIATION: 'COL',
    CATEGORIES: {
      PERFORMANCE: COLOMBIA_PERFORMANCE_CATEGORIES,
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: {},
    },
    DEFAULTS: {
      GRADES: [],
      LOCATION: {
        municipalityName: 'Bogotá D.C.',
        coordinates: {
          // Plaza de Bolívar
          lat: 4.595136,
          lng: -74.077666,
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.KM,
      CURRENCY: 'COP',
      LOCALE: 'es-CO',
      SHAPE: {
        POLYGON: 'deparmentsColombia.json',
        DISTRICT: 'MunicipiosColombia.json',
      },
      FILTERS: {
        GRADES: [],
        PAYMENTS: [],
        PERFORMANCE: [],
        DISTANCE: Number.NaN,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ARROWS,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3, 17, 18, 19],
      PUBLIC_ADMISSION_SYSTEMS: [1, 3],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: false,
      },
      CARD: {
        PROPERTIES: DEFAULT_CARD_PROPERTIES,
      },
      FILTERS: {
        PARTNERSHIPS: true,
        PAYMENT: true,
        PERFORMANCE: true,
        SPECIALTIES: false,
        VACANCIES: true,
      },
      SIMULATION: {
        ENABLED: false,
        YEAR_RANGE: [],
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: false,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: false,
        ORGANIC: false,
        STEP: {},
      },
    },
    PUBLIC_ENROLLMENT: {
      ENABLED: false,
      DEFAULT_PROCESS: '',
      DEFAULT_PROCESS_CODE: '',
    },
  },
  PALMIRA: {
    NAME: 'Palmira',
    ABBREVIATION: 'PAL',
    CATEGORIES: {
      PERFORMANCE: COLOMBIA_PERFORMANCE_CATEGORIES,
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: {},
    },
    DEFAULTS: {
      GRADES: [],
      LOCATION: {
        municipalityName: 'Palmira',
        coordinates: {
          // Parque Bolívar
          lat: 3.5272094,
          lng: -76.3004958,
        },
        SHAPE: {
          POLYGON: 'deparmentsColombia.json',
          DISTRICT: 'MunicipiosColombia.json',
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.KM,
      CURRENCY: 'COP',
      LOCALE: 'es-CO',
      FILTERS: {
        GRADES: [],
        PAYMENTS: [],
        PERFORMANCE: [],
        DISTANCE: Number.NaN,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ALPHA,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3, 17, 18, 19],
      PUBLIC_ADMISSION_SYSTEMS: [1, 3],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: false,
      },
      CARD: {
        PROPERTIES: DEFAULT_CARD_PROPERTIES,
      },
      FILTERS: {
        PARTNERSHIPS: true,
        PAYMENT: true,
        PERFORMANCE: true,
        SPECIALTIES: false,
        VACANCIES: true,
      },
      SIMULATION: {
        ENABLED: false,
        YEAR_RANGE: [],
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: false,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: false,
        ORGANIC: false,
        STEP: {},
      },
    },
    PUBLIC_ENROLLMENT: {
      ENABLED: false,
      DEFAULT_PROCESS: '',
      DEFAULT_PROCESS_CODE: '',
    },
  },
  NEWHAVEN: {
    NAME: 'New Haven',
    ABBREVIATION: 'NH',
    CATEGORIES: {
      PERFORMANCE: /* TODO: NEWHAVEN_PERFORMANCE_CATEGORY */ {},
      PAYMENT: TETHER_PAYMENT_CATEGORY,
      SCHOLARSHIPS: {},
    },
    DEFAULTS: {
      GRADES: [],
      LOCATION: {
        municipalityName: 'New Haven',
        coordinates: {
          // New Haven Green
          lat: 41.308274,
          lng: -72.927883,
        },
      },
      MEASUREMENT_UNIT: MEASUREMENT_UNIT.MI,
      CURRENCY: 'USD',
      LOCALE: 'en-US',
      SHAPE: {
        POLYGON: '',
        DISTRICT: '',
      },
      FILTERS: {
        GRADES: [],
        PAYMENTS: [],
        PERFORMANCE: [],
        DISTANCE: Number.NaN,
      },
    },
    STYLES: {
      PERFORMANCE_ICON: PERFORMANCE_ICON_STYLE.ARROWS,
    },
    SPECIAL_IDS: {
      DAYCARE_GRADES: [1, 2, 3],
      PUBLIC_ADMISSION_SYSTEMS: [1],
    },
    SETTINGS: {
      CHATBOT: {
        ENABLED: false,
      },
      CARD: {
        PROPERTIES: CARD_PROPERTIES_NEW_HAVEN,
      },
      FILTERS: {
        PARTNERSHIPS: false,
        PAYMENT: false,
        PERFORMANCE: false,
        SPECIALTIES: false,
        VACANCIES: false,
      },
      SIMULATION: {
        ENABLED: true,
        YEAR_RANGE: [2019, 2023],
      },
      LINK_BANK_ACCOUNT: {
        ENABLED: false,
      },
      IFRAME: {
        SEARCH_MODE: GEO_SEARCH_MODES.RADIAL,
      },
      RESEARCH: {
        ENABLED: false,
        ORGANIC: false,
        STEP: {},
      },
    },
    PUBLIC_ENROLLMENT: {
      ENABLED: false,
      DEFAULT_PROCESS: '',
      DEFAULT_PROCESS_CODE: '',
    },
  },
});

const TENANT_CONFIGURATION = TETHER_TENANT[CONFIG.tenant.toUpperCase()];
const SHOW_CHATBOT = TENANT_CONFIGURATION?.SETTINGS?.CHATBOT?.ENABLED;
export { TENANT_CONFIGURATION, SHOW_CHATBOT };
